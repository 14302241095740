body {
  background-color: rgb(20, 27, 32);
}
.App {
  text-align: center;
  width: 100vw;
}
#nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background-color: rgb(20, 27, 32);
  color: white;
  right: 0;
  box-shadow: 5px 10px 18px #888888;
  position: relative;
}
ul {
  display: flex;
  justify-content: space-around;
}
li {
  color: white;
  list-style: none;
  margin-left: 3vw;
}
.cm {
  margin-right: -4rem;
  border: 3px solid white;
  text-align: center;
  color: #f6eff7;
}
h6 {
  background-color: rgb(83, 83, 83);
  margin: 0%;
}
.painting {
  margin: 5px;
  padding: 1rem;
  box-shadow: 5px 10px 18px #888888;
}
.Gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: rgb(235, 235, 235);
}
.thumbnail {
  position: relative;
  width: 200px;
  height: 150px;
  overflow: hidden;
}
.thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.thumbnail img.portrait {
  width: 100%;
  height: auto;
}
.text {
  mix-blend-mode: screen;
  letter-spacing: 0.5rem;
  font-weight: lighter;
  font-size: xx-large;
}
#detail {
  width: 60vw;
  position: relative;
  overflow: hidden;
}

.contact-div {
  width: 50vw;
  height: 50vh;
}
.contact-li:hover {
  color: #ffffff;
  background-color: rgb(20, 27, 32);
  text-shadow: #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 15px,
    #ff2d95 0px 0px 20px, #ff2d95 0px 0px 30px, #ff2d95 0px 0px 40px,
    #ff2d95 0px 0px 50px, #ff2d95 0px 0px 75px;
}
.nav-link {
  text-decoration: none;
}
.nav-link :active {
  color: #ffffff;
  background-color: rgb(20, 27, 32);
  text-shadow: #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 15px,
    #f81e8b 0px 0px 20px, #f8218d 0px 0px 30px, #fc1e8d 0px 0px 40px,
    #f01e87 0px 0px 50px, #f80880 0px 0px 75px;
}
.contact-li:hover {
  color: #ffffff;
  background-color: rgb(20, 27, 32);
  text-shadow: #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 15px,
    #ff2d95 0px 0px 20px, #ff2d95 0px 0px 30px, #ff2d95 0px 0px 40px,
    #ff2d95 0px 0px 50px, #ff2d95 0px 0px 75px;
}
@media only screen and (min-width: 600px) {
  #quote {
    font-size: 2rem;
    width: 90vw;
    padding-top: 1.3em;
  }
}
@media only screen and (max-width: 600px) {
  #quote {
    width: 93vw;
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 600px) {
  #contactStatement {
    font-size: 2.5rem;
    /* width: 90vw; */
    padding-top: 1.3em;
  }
}
@media only screen and (max-width: 600px) {
  #contactStatement {
    /* width: 93vw; */
    padding-top: 1.3em;
    font-size: 1.8rem;
  }
}
